import React, { useState, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input'
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import i18n from '../utils/i18n'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from "@material-ui/core/Grid";

import { ProfileWrapper } from '../components'

import { createUserForPartner } from '../services/functions'

import { signInWithEmailAndPassword, sendPasswordResetEmail } from '../services/auth'
import { isPartnerFROnly, partnerCopySuffix } from '../utils';

const t = i18n.instance().t
const getLanguage = i18n.instance().getLanguage
const Login = (props) => {

  const { startLoading, stopLoading, showError, showDialog, hideDialog, showSnackbar, partner, history, historyPush, historyReplace } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verificationPassword, setVerificationPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [sniNumber, setSniNumber] = useState('')
  const [content, setContent] = useState('login')
  const [enterpriseNumber, setEnterpriseNumber] = useState('')
  const [postcode, setPostcode] = useState('')
  const [terms, setTerms] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false)

  const passwordInput = useRef(null)

  useEffect(() => {
    let components = history.location.pathname.split('/')
    let path = components[components.length - 1]
    if(path === 'reset-password') {
      setContent('reset')
    } else if(path === 'register') {
      setContent('register')
    } else {
      setContent('login')
    }
  }, [history.location])

  const focusTextInput = (input) => {
    if(!!input && !!input.current) {
      input.current.focus();
    }
  }

  const submitEmail = () => {
    focusTextInput(passwordInput)
  }

  const requireSNI = () => {
    return partner.sni_number_required
  }

  const submitPassword = () => {
    if(content === 'login') {
      submit()
    }
  }

  const submit = async () => {
    if(!email || email === '') {
      showSnackbar({ text: t('auth.enter_email'), color: 'warning' })
      return
    }
    if(content === 'reset') {
      submitReset()
    } else if(content === 'register') {
      submitRegister()
    } else if(content === 'login') {
      submitLogin()
    }
  }

  const submitLogin = async () => {
    if(!password || password === '') {
      showSnackbar({ text: t('auth.enter_password'), color: 'warning' })
      return
    }
    startLoading('checking', t('auth.checking'))
    let result = await signInWithEmailAndPassword(email, password)
    stopLoading('checking')
    if(result.error) {
      showError(t(`auth.${result.error.code}`))
    } else if(!!result.user) {
      historyReplace('/dashboard')
    }
  }

  const submitRegister = async () => {
    let checkedEnterpriseNumber = checkEnterpriseNumber(enterpriseNumber)
    if(!password || password === '') {
      showSnackbar({ text: t('auth.enter_password'), color: 'warning' })
      return
    } else if(password !== verificationPassword) {
      showSnackbar({ text: t('auth.password_mismatch'), color: 'warning' })
      return
    } else if(requireSNI() && !sniNumber) {
      showSnackbar({ text: t('auth.sni_missing'), color: 'warning' })
      return
    } else if(requireSNI() && !postcode){
      showSnackbar({ text: t('auth.postcode_missing'), color: 'warning'})
      return
    } else if (requireSNI() && !checkedEnterpriseNumber) {
      showSnackbar({ text: t('auth.enterprise_number_wrong'), color: 'warning'})
      return
    } else if(requireSNI() && !terms ) {
      showSnackbar({ text: t('auth.terms_and_privacy_warning'), color: 'warning' });
      return
    } else if(requireSNI() && !privacyPolicy ) {
      showSnackbar({ text: t('auth.terms_and_privacy_warning'), color: 'warning' });
      return
    }

    startLoading('creating', t('auth.creating_acc'))

    let result = await createUserForPartner(email, password, partner.id, sniNumber, getLanguage(), enterpriseNumber, postcode)
    stopLoading('creating')
    if(result.error) {
      showError(t(`auth.${result.error.code}`))
    } else {
      await signInWithEmailAndPassword(email, password)
      historyReplace('/onboarding')
    }
  }

  const checkEnterpriseNumber = (enterpriseNumber) =>{
    let cleanedEnterpriseNumber = enterpriseNumber.replace(/[^0-9]/g, '')
    if (!enterpriseNumber) {
      return true
    } else {
       return /[0,1][0-9]{9}$/.test(cleanedEnterpriseNumber)
    }
  }

  const submitReset = async () => {
    startLoading('sending', t('auth.sending_reset'))
    let result = await sendPasswordResetEmail(email, getLanguage())
    stopLoading('sending')
    if(result.error) {
      showError(t(`auth.${result.error.code}`))
    } else {
      showDialog(t('general.success_title'), t('auth.reset_success'), [{
        title: t('general.close'),
        onClick: () => { hideDialog() }
      }])
    }
  }


  const togglePasswordReset = () => {
    let path
    if(content === 'reset') {
      path = '/login'
    } else {
      path = '/reset-password'
    }
    historyPush(path)
  }

  const toPasswordReset = () => {
    historyPush('/reset-password')
  }

  const toLogin = () => {
    historyPush('/login')
  }

  const toRegister = () => {
    historyPush('/register')
  }

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }
  return (
    <div className="login-background pt-5">
      <Container maxWidth="sm" className="login-container">
        <div className="stretch column">
          <div>
            <Fade in={content === 'login'} className="fading-label">
              <h4 className={`card-title`}>{ t('auth.login') }</h4>
            </Fade>
            <Fade in={content === 'register'} className="fading-label">
              <h4 className={`card-title`}>{ t('auth.signup') }</h4>
            </Fade>
            <Fade in={content === 'reset'} className="fading-label">
              <h4 className={`card-title`}>{ t('auth.reset_pass') }</h4>
            </Fade>
            <h4 className="card-title fading-label-background">{ t('auth.reset_pass') }</h4>
          </div>

          <TextField
            margin="normal"
            className="stretch mt-2"
            id={'auth_email'}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                submitEmail()
                ev.preventDefault()
              }
            }}
            label={t('general.email')}
            type="email"
            value={email}
            onChange={(t) => { setEmail(t.target.value) }}
          />

          <Collapse in={content !== 'reset'}>
            <FormControl
              className="stretch mt-2"
              margin="normal">
              <InputLabel htmlFor="auth_password">{ t('auth.password') }</InputLabel>
              <Input
                id="auth_password"
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    submitPassword()
                    ev.preventDefault()
                  }
                }}
                ref={passwordInput}
                type={passwordVisible ? 'text' : 'password'}
                value={password}
                onChange={(t) => { setPassword(t.target.value) }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Collapse>

          <Collapse in={content === 'register'}>
            <TextField
              margin="normal"
              className="stretch mt-2"
              label={t('auth.verify_password')}
              type="password"
              value={verificationPassword}
              onChange={(t) => { setVerificationPassword(t.target.value) }}
            />
          </Collapse>

          <Collapse in={content !== 'register'}>
            <Button className="flexible-button mt-3" onClick={togglePasswordReset}>
              { content === 'login' ? t('auth.to_pass_reset') : t('auth.close_pass_reset') }
            </Button>
          </Collapse>



          <Collapse in={partner.sni_number_required && content === 'register'}>

            <ProfileWrapper className="mt-4" title={t('auth.sni_postcode')} >
              <TextField
                  margin="normal"
                  className="stretch mt-2"
                  label={t('auth.sni')}
                  type="text"
                  value={sniNumber}
                  onChange={(t) => { setSniNumber(t.target.value) }}
                />
              <TextField
                margin="normal"
                className="stretch mt-2"
                label={t('auth.postcode')}
                type="number"
                value={postcode}
                onChange={(t) => { setPostcode(t.target.value) }}
              />
            </ProfileWrapper>

            <ProfileWrapper className="mt-4" title = {t("onboarding.enterprise_number", {}, isPartnerFROnly(partner) ? '__fr' : '')}>
              <div>{t('auth.cde_info')}</div>
                <TextField
                margin="normal"
                className="stretch mt-2"
                label={t('profile.enterprise_number', {}, partnerCopySuffix(partner))}
                type="email"
                value={enterpriseNumber}
                onChange={(t) => { setEnterpriseNumber(t.target.value) }}
              />
            </ProfileWrapper>

            <FormControlLabel
              control={<Checkbox color="primary" checked={terms} onChange={(e) => setTerms(e.target.checked)} />}
              label={t('auth.terms')}
              className="mt-2"
            />

            <FormControlLabel
              control={<Checkbox color="primary" checked={privacyPolicy} onChange={(e) => setPrivacyPolicy(e.target.checked)} />}
              label={t('auth.privacy_policy')}
            />

          </Collapse>

          <Button color="primary" variant="contained" className="flexible-button mt-3" onClick={submit}>
            { content === 'reset' ? t('auth.send_reset') : content === 'register' ? t('auth.create_acc') : t('auth.login') }
          </Button>

          <Collapse in={content !== 'reset'}>
            <Button className="flexible-button mt-3" onClick={content === 'login' ? toRegister : toLogin}>
              { content === 'login' ? t('auth.to_signup') : t('auth.to_login') }
            </Button>
          </Collapse>

        </div>
      </Container>
    </div>
  )
}

export default Login